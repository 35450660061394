.btn {
  border: none;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  text-transform: uppercase;
  padding: 1rem 2.4rem;
  letter-spacing: 1px;
  transition: .2s all;

  &-default {

    &-transparent {
      background: rgba(white, .7);
    }
  }

  &-transparent {
    background: transparent;
  }

  &-primary {
    &:hover, &:focus {
      background: $brand-primary--700 !important;
    }
    &-transparent {
      background: rgba($brand-primary, .5);
    }
  }

  &-secondary {
    background: $brand-secondary;

    &:hover, &:focus {
      background: darken($brand-secondary, .6);
    }
  }

  &-info {
    &:hover, &:focus {
      background: $gray--600 !important;
    }
  }

  &-success {
    @extend .btn-secondary;

    &:hover, &:focus {
      background: $brand-secondary--600 !important;
    }
  }

  &-danger {

  }

  &-arrow {
    text-transform: capitalize;
    padding: 0;
    letter-spacing: 0;
    text-decoration: none !important;
    position: relative;

    &:hover, &:focus {
      &:before {
        content: "";
        position: absolute;
        bottom: .3rem;
        right: 0;
        left: 0;
        background: darken($brand-primary, 10%);
        height: 1px;
      }
    }
    &:after {
      content:"\f0da";
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      font-size: 2rem;
      margin-left: .9rem;
      position: relative;
      bottom: -.1rem;
    }
  }

  &-link {
    text-transform: capitalize;
    padding: 0;
    letter-spacing: 0;
    text-decoration: none !important;
    position: relative;
    padding: .4rem 0;
    margin-bottom: .6rem;

    &:after {
      content: "";
      position: absolute;
      bottom: .3rem;
      right: 0;
      left: 0;
      background: darken($gray--300, 10%);
      height: 2px;
    }


    &:before {
      content:"\f0da";
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      font-size: 1.8rem;
      margin-right: .9rem;
      position: relative;
      bottom: -.2rem;
    }
  }
}

.form-submit, .form-search button[type=submit], .search-form button[type=submit] {
  @extend .btn-success;

  &:hover, &:focus {
    background: $brand-secondary--600 !important;
  }
}

.btn-wrapper {
  a {
    @extend .btn;
  }
  &--default a {
    @extend .btn-default;
  }
  &--arrow a {
    @extend .btn-arrow;
  }
}