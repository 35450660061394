.field-name-field-faq-link a {
    @include bp-nav {
        position: fixed;
        right: 0;
        top: 50%;
    }

}
.description {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:before, &:after {
        display: none;
    }

    > h3 {
        width: 100%;
    }

    .content.has-image {
        width: 100%;
        @include bp-sm {
            width: calc(100% - 23.5rem);
        }
    }

    .image-caption {
        background: $gray--200;
        margin-bottom: 1.5rem;
        width: 100%;

        @include bp-sm {
            float: right;
            width: 22rem;
            margin-left: 1.5rem;
        }

        .caption {
            padding: 1.5rem;
        }
    }
}

.field-name-field-product-code {
    display: none;
}



.product-nav {
    z-index: 5 !important;

    .sticky-wrapper {
        height: auto !important;

        @include bp-nav {
            height: inherit !important;
        }
    }

    .sticky-wrapper.is-sticky & {
        left: 0;
        right: 0;
        width: 100% !important;
        top: 152px!important;
        margin-top: 0;
        position: static !important;

        @include bp-nav {
            position: fixed !important;
        }


        .nav {
            margin-top: 0;
        }
    }

    .nav-wrapper {
        border-bottom: solid 4px $gray--400;
        margin-bottom: 1.5rem;
        background: white;
        position: relative;
        z-index: 899 !important;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -34px;
            height: 30px;
            background: linear-gradient(to bottom, rgba(0,0,0,.0) 0%,rgba(0,0,0,0) 100%);
        }

        @include bp-md {
            margin-bottom: 3rem;

            .is-sticky &:after {
                background: linear-gradient(to bottom, rgba(0,0,0,.2) 0%, rgba(0,0,0,.1) 40%, rgba(0,0,0,0) 100%);
            }
        }
    }

    .nav {
        @extend .container;
        //margin-top: -2.5rem;
        display: flex;
        float: none;
        font-weight: 500;
        font-family: $headings-font-family;
        text-transform: uppercase;
        flex-direction: column;

        @include bp-nav {
            flex-direction: row;
        }


        &:before, &:after {
            display: none;
        }

        li {
            margin-bottom: -5px;
            margin-right: 2rem;
            border-bottom: solid 6px transparent;

            &.active {
                border-bottom: solid 6px $brand-primary;
            }
        }

        a {
            background: transparent !important;
            color: $text-color;
            padding: 1rem 0 .9rem;

            @include bp-nav {
                padding: 1.5rem 0;
            }


            .is-sticky & {
                padding: 1rem 0 .9rem;
            }
        }
    }
}

.prod-tab-anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;

    &:focus {
        outline: none;
    }
}

.prod-tab-content {
    padding: 3rem 0;
    position: relative;
    outline: none;
    overflow: hidden;

    > h3 {
        outline: none;
        overflow: unset;

        &:before {
            display: block;
            content: " ";
            margin-top: -180px;
            height: 180px;
            visibility: hidden;
            pointer-events: none;
            position: relative;
            left: -9999px;
            z-index: -1;
        }
    }

    .btn {
        padding: 1rem 1.5rem;
    }

    &:nth-child(even) {
        background: $gray--200;
        @extend .full-width;
    }

    &.prod-downloads {
        padding-bottom: 3rem;

        .item {
            width: 100%;

            @include bp-sm {
                width: 50%;
            }

        }
    }

    &.prod-published {
        strong {
            @extend .h6;
            font-size: 1.8rem !important;
            display: block;
            margin-bottom: 0;
            margin-top: 2rem;
        }

        #publication-collapse {
            min-height: 240px;
            height: 0;
            display: block !important;
            overflow: hidden;
            position: relative;

            + button .more {
                display: inline;
            }

            + button .less {
                display: none;
            }

            &.in {
                height: 100%;
                &:after {
                    //display: none;
                    height: 0;
                }

                + button .more {
                    display: none;
                }

                + button .less {
                    display: inline;
                }
            }

            &:after {
                content: '';
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                display: block;
                position: absolute;
                width: 100%;
                height: 120px;
                bottom: 0;
                left: 0;
                right: 0;
            }

            > div.view  {
                margin-bottom: 3rem;
            }

            + button {
                //margin-top: -3rem;
            }
        }


    }

    &.prod-details {

    }

    &.product_table {
        .table-responsive {
            border: none;
        }

        > h3 {
            margin-bottom: 1.5rem;
        }
        table {
            thead tr {
                @include bp-xs-max {
                    display: none;
                }

                &:first-child {
                    background: $brand-primary;

                    th {
                        color: white;
                        font-family: $headings-font-family;
                        font-size: 1.6rem;
                        line-height: 1;
                        text-transform: uppercase;
                        font-weight: 300;
                    }
                }

            }

            td:first-child,  th:first-child {
                max-width: 47rem;
                text-align: left;
            }

            td:last-child, th:last-child {
                text-align: left;
            }

            tr {
                background: transparent;
            }

            th, td {
                padding: 1rem;
                text-align: center;
                vertical-align: middle;
            }

            tbody {
                @include bp-xs-max {

                    tr {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;

                        &:before, &:after {
                            display: none;
                        }

                        td {
                            &:first-child, &:last-child {
                                width: 100%;
                            }

                            &:nth-child(2), &:nth-child(3), &:nth-child(4)  {
                                width: 33.333%;
                                text-align: left;
                            }
                        }

                    }
                }

                tr:nth-child(even) {
                    background: $gray--200;
                }

            }
        }

        .commerce-add-to-cart {
            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:before, &:after {
                    display: none;
                }

                [id^=edit-line-item-fields] {
                    display: none;
                }
            }

            label {
                display: none;
            }

            button {
                margin-left: 4px;
            }
        }
    }

    &:first-of-type {
        padding-top: 0;
        @extend .clearfix;
    }

    > h3 {
        margin-top: 0;
        margin-bottom: 2rem;
    }
}

.form-item-quantity, [class^="form-item-edit-quantity"], .views-field-edit-quantity .form-item  {
    margin: 0;
    display: flex;
    justify-content: space-between;
    max-width: 11rem;
    align-items: center;

    &:before, &:after {
        display: none;
    }

    input {
        text-align: center;
        order: -1;
        margin-right: 1rem;
        padding-left: 0;
        padding-right: 0;
        min-width: 3rem;

        + span {
            margin-left: .5rem;
        }
    }

    .button {
        background: $brand-primary;
        color: white;
        text-decoration: none;
        padding: 0 !important;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        margin-bottom: 0 !important;
        border-radius: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .commerce-quantity-plusminus-link-decrease a {
        padding-bottom: 0.4rem !important;
    }
}

.view-product-types {
    .view-filters {
        display: none !important;
    }
    body.page-antibodies &, body.page-taxonomy-term-668 &,
    body.page-elisa &, body.page-taxonomy-term-671 &,
    body.page-growth-factors-cytokines &, body.page-taxonomy-term-700 & {
        .view-filters {
            display: block !important;
            opacity: 1 !important;
            margin-bottom: 3rem;
        }
    }

    .form-control {
        height: 38px;
    }

    #edit-submit-product-types {
        margin-top: 0;
    }
}
