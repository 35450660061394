.full-width {
  margin-left: -15px !important;
  margin-right: -15px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px) !important; // half of 720 (750px - 30px of padding)
    margin-right: calc(-50vw + 360px) !important; // half of 720 (750px - 30px of padding)
    padding-left: calc(50vw - 360px) !important;
    padding-right: calc(50vw - 360px) !important;
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 470px) !important; // half of 940 (970px - 30px of padding)
    margin-right: calc(-50vw + 470px) !important; // half of 940 (970px - 30px of padding)
    padding-left: calc(50vw - 470px) !important;
    padding-right: calc(50vw - 470px) !important;
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 570px) !important; // half of 1140 (1170px - 30px of padding)
    margin-right: calc(-50vw + 570px) !important; // half of 11400 (1170px - 30px of padding)
    padding-left: calc(50vw - 570px) !important;
    padding-right: calc(50vw - 570px) !important;
  }
}

.full-width--8-col {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    padding-left: calc(50vw - 360px);
    padding-right: calc(50vw - 360px);
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 316px); // half of 940 (970px - 30px of padding)
    margin-right: calc(-50vw + 316px); // half of 940 (970px - 30px of padding)
    padding-left: calc(50vw - 316px);
    padding-right: calc(50vw - 316px);
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 382px); // half of 1140 (1170px - 30px of padding)
    margin-right: calc(-50vw + 382px); // half of 11400 (1170px - 30px of padding)
    padding-left: calc(50vw - 382px);
    padding-right: calc(50vw - 382px);
  }
}


.full-width--left {
  margin-left: -15px;
  padding-left: 15px;

  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    padding-left: calc(50vw - 360px);
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    padding-left: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
    padding-left: calc(50vw - 570px);
  }
}

.full-width--right {
  margin-right: -15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    padding-right: calc(50vw - 360px);
  }

  @media (min-width: 992px) {
    margin-right: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    padding-right: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    margin-right: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
    padding-right: calc(50vw - 570px);
  }
}

.mb{
  &-0 {
    margin-bottom: 0 !important;
  }
  &_5 {
    margin-bottom: 0.5rem !important;
  }
  &-1 {
    margin-bottom: 1rem !important;
  }
  &-1_5 {
    margin-bottom: 1.5rem !important;
  }
  &-2 {
    margin-bottom: 2rem !important;
  }
  &-3 {
    margin-bottom: 3rem !important;
  }
  &-4 {
    margin-bottom: 4rem !important;
  }
  &-5 {
    margin-bottom: 5rem !important;
  }
  &-6 {
    margin-bottom: 6rem !important;
  }
}

.mt {
  &-auto {
    margin-top: auto !important;
  }
  &-0 {
    margin-top: 0 !important;
  }
  &_5 {
    margin-top: .5rem !important;
  }
  &-1 {
    margin-top: 1rem !important;
  }
  &-1_5 {
    margin-top: 1.5rem !important;
  }
  &-2 {
    margin-top: 2rem !important;
  }
  &-3 {
    margin-top: 3rem !important;
  }
  &-4 {
    margin-top: 4rem !important;
  }
  &-5 {
    margin-top: 5rem !important;
  }
  &-6 {
    margin-top: 6rem !important;
  }
}

.mx {
  &-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  &-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  &-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  &-1_5 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  &-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  &-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  &-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  &-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  &-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
}

.ml {
  &-auto {
    margin-left: auto !important;
  }
}
.my {
  &-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  &-1_5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  &-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  &-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  &-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  &-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  &-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
}

.pb{
  &-0 {
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-bottom: 1rem !important;
  }
  &-1_5 {
    padding-bottom: 1.5rem !important;
  }
  &-2 {
    padding-bottom: 2rem !important;
  }
  &-3 {
    padding-bottom: 3rem !important;
  }
  &-4 {
    padding-bottom: 4rem !important;
  }
  &-5 {
    padding-bottom: 5rem !important;
  }
  &-6 {
    padding-bottom: 6rem !important;
  }
}

.pt {
  &-0 {
    padding-top: 0 !important;
  }
  &-1 {
    padding-top: 1rem !important;
  }
  &-1_5 {
    padding-top: 1.5rem !important;
  }
  &-3 {
    padding-top: 3rem !important;
  }
  &-4 {
    padding-top: 4rem !important;
  }
  &-5 {
    padding-top: 5rem !important;
  }
  &-6 {
    padding-top: 6rem !important;
  }
}

.px {
  &-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  &-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  &-1_5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  &-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  &-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  &-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  &-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  &-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
}

.py {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-0_5 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }
  &-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  &-1_5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  &-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  &-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  &-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  &-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  &-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
}

.w-{
  &100 {
    width: 100%;
  }
}

.border {
  &-top {
    border-top: 1px solid transparent;
  }
  &-bottom {
    border-bottom: 1px solid transparent;
  }
  &-width-2 {
    border-width: 2px !important;
  }
  &-width-3 {
    border-width: 3px !important;
  }

  &-gray--200 {
    border-color: $gray--200 !important;
  }
  &-gray--300 {
    border-color: $gray--300 !important;
  }
  &-gray--400 {
    border-color: $gray--400 !important;
  }
  &-gray--500 {
    border-color: $gray--500 !important;
  }
  &-gray--600 {
    border-color: $gray--600 !important;
  }
  &-gray--700 {
    border-color: $gray--700 !important;
  }
}

.bg {
  &-gray--200 {
    background: $gray--200 !important;
  }
  &-gray--300 {
    background: $gray--300 !important;
  }
  &-gray--400 {
    background: $gray--400 !important;
  }
  &-gray--500 {
    background: $gray--500 !important;
  }
  &-gray--600 {
    background: $gray--600 !important;
  }
  &-gray--700 {
    background: $gray--700 !important;
  }
  &-white {
    background: white !important;
  }
  &-transparent {
    background: transparent !important;
  }
  &-primary {
    background: $brand-primary !important;
  }

  &-landing-primary {
    background: $brand-landing-primary !important;
  }
}

.text {
  &-gray--200 {
    color: $gray--200 !important;
  }
  &-white {
    color: white !important;
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}

.hidden-nav {
  display: block;

  @include bp-nav {
    display: none;
  }
}

.visible-nav {
  display: none !important;

  @include bp-nav {
    display: block !important;;
  }
}

.card {
  background: white;
  border-bottom: $gray--300 solid 2px;
  padding: 1.5rem;
}

.dflex {
  display: flex;

  &:before, &:after {
    display: none;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.float-left {
  @include bp-sm {
    float: left;
    margin: 0 20px 15px 0;
    margin-bottom: 0;
  }

}
