.search-results {
    .search-result {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: solid 1px $gray--300;

        &:last-child {
            border-bottom: none;

        }

        .title {
            margin-top: 0;
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        .search-snippet {
            margin-bottom: 0;
        }
    }
}