body.page-node-3030 {
  .page--header {
    background: transparent;
    color: $text-color;
    text-align: center;
    padding-top: 0;

    h1 {
      @media (min-width: 768px) {
        font-size: 3.5rem;
      }

      @media (min-width: 1200px) {
        font-size: 4rem;
      }
    }
  }

  .footer {
    padding-top: 0;

    img {
      margin-bottom: 6rem;
    }
  }

  .slick-slider .slick-prev {
    left: 10px;
  }

  .slick-slider .slick-next {
    right: 10px;
  }

  .bg-landing-primary a {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .prod-tab-content.product_table table td,
  .prod-tab-content.product_table table th {
    vertical-align: top;
  }

  .bg-landing-primary {
    background: #363636 !important;
  }


  .content .product_table table td,
  .content .product_table table th {
    text-align: left;
  }
}
