#commerce-quickorder-form {
    margin-bottom: 30px;
    position: relative;

    .panel {
        border: none;
        box-shadow: none;
        padding: 0;

        &-heading {
            background: transparent;
            border: none;
            margin-bottom: 0;
        }
    }
    
    #edit-items-1-body {
        display: flex;

        &:before, &:after {
            display: none;
        }

        .form-item {
            margin-right: 15px;
            margin-bottom: 0;
        }
    }
    
    > div > button {
        position: absolute;
        bottom: 15px;
        left: 195px;
        @extend .btn-primary;
    }


}