.megamenu {
    font-family: $headings-font-family;
    font-weight: 400;
    text-transform: uppercase;

    .nav-tabs {
        font-weight: 500;
        display: none;
        border-bottom: solid 1px $gray--300;
        padding: 0 3rem;
        margin: 0 -3rem;

        .navbar-default & {
            padding: 0 1.5rem;
            margin: 0;
        }

        @include bp-nav {
            display: block;
        }


        @include bp-nav {
            .navbar-default & {
                padding: 0 3rem;
            }
        }

        li {
            width: 100%;
            background: $gray--200;
            margin-right: .5rem;

            a {
                border-bottom: $gray--300 solid 1px;
                margin: 0;
            }

            @include bp-nav {
                width: auto;
            }

            &.active {
                background: transparent;

                > a {
                    border-bottom-color: transparent !important;
                }
            }
        }

    }

    .tab-pane {
        display: block;
        opacity: 1;
        outline: none;

        &#term663 {
            ul {
                border-bottom: solid 1px $gray--300;

            }
            li {
                border-bottom: solid 1px $gray--300;
                padding-bottom: 1.5rem;
                margin-top: 1.5rem;
                margin-bottom: -1px;
            }
        }

        .navbar & {
            padding: 1.5rem
        }

        @include bp-nav {
            display: none;

            .navbar & {
                padding: 1.5rem 3rem;
            }

            &.in {
                display: block;
            }
        }


        h5 {
            border-bottom: solid 4px $gray--200;
            padding-bottom: .5rem;

            @include bp-nav {
                display: none;
            }
        }

        .navbar & h6 {
            //padding-left: 1rem;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            border-bottom: solid 1px $gray--300;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            padding-left: 0 !important;

            .navbar & {
                padding-left: 1rem;
            }

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }

            &:before, &:after {
                display: none;
            }

            li {
                margin-bottom: .5rem;
                width: 100%;

                @include bp-nav {
                    width: 33.333%;
                }

            }
        }
    }
}