// make all images responsive by default
img {
  @extend .img-responsive;
}

// fix for bootstrap media component
.media img {
  max-width: none;
}

/*
|--------------------------------------------------------------------------
| Slick Slider
|--------------------------------------------------------------------------
*/
.slick-slider {
  display: table !important;
  table-layout: fixed !important;
  width: 100%;
  margin-bottom: 0;

  img {
    width: 100%;
  }

  .slick-dots {
    bottom: -30px;

    li {
      button {
        &:before {
          content: "";
          border: 2px solid white;
          box-shadow: 0 0 6px rgba(black, .6), inset 0 0 6px rgba(black, .3);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          opacity: 1;
          top: 5px;
          left: 5px;
        }
      }

      &.slick-active button:before {
        background: white;
        box-shadow: 0 0 6px rgba(black, .6);

      }
    }
  }

  .slick-arrow:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $gray--400 !important;
    //text-shadow: 0 0 9px rgba(white, .3);
    opacity: 1;
    font-size: 3.6rem;
  }

  .slick-next {
    right: -10px;

    &:before {
      content:"\f105";
    }
  }

  .slick-prev {
    left: -10px;
    z-index: 9;

    &:before {
      content:"\f104";
    }
  }
}