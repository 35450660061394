.footer {
    margin-top: auto;
    background: $gray--700;
    color: white;
    padding-top: 6rem;

    a {
        color: $gray--400;

        &:hover, &:focus {
            background: transparent;
            color: white;
        }
    }

    .fa {
        color: $brand-primary--700;
    }

    .region-footer {
        @extend .row;
        @extend .clearfix;
        display: flex;
        flex-wrap: wrap;
        
        &:before, &:after {
            display: none;
        }

        @include bp-md {
            display: block;
        }
        
        + div {
            clear: both;
        }

        > section {
            margin-bottom: 3rem;

            @include bp-xs-max {
                width: 100%;
            }

        }

    }

    .block-title {
        @extend h5;
    }

    .block-title, .block > h5 {
        margin-top: 0;
        margin-bottom: 2rem;

        &:before {
            content: "";
            height: 3px;
            background: $gray--500;
            width: 60px;
            display: block;
            margin-bottom: 1.5rem;
        }
    }

    .links {
        li {
            margin-bottom: 1rem;
        }

        .menu a {
            display: inline;
            background: transparent !important;
            padding: 0;
            font-family: $headings-font-family;
            text-transform: uppercase;
        }
    }

    .contact {
        > div:not(.contextual-links-wrapper) {
            padding-left: 3rem;
            position: relative;
            margin-bottom: 1rem;
            min-height: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:before, &:after {
                display: none;
            }

        }

        .fas {
            color: $brand-primary--700;
            font-size: 2.4rem;
            position: absolute;
            left: 0;
        }
    }

    .social {
        @include bp-md {
            margin-bottom: 3rem;
        }

        > ul {
            font-size: 3rem;
        }
    }

    .newsletter {
        form > div {
            display: flex;

            &:before, &:after {
                display: none;
            }

            div {
                margin: 0;
            }
            
        }
    }

}