.navbar-nav {
    font-family: $headings-font-family;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
}

#navbar-sticky-wrapper {
    height: auto !important;
}

header.navbar {
    border: none;
    background: $brand-primary;
    margin-bottom: 0;
    box-shadow: 1px 1px 1px rgba(0,0,0, .0);

    .is-sticky & {
        box-shadow: 1px 1px 21px rgba(0,0,0, .6);
        z-index: 929 !important;
    }

    a.phone {
        color: blue;
        &:before {
            content: "\f095";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            display: inline;
            margin-right: .5rem;
        }
    }

    .menu {
        float: none;
    }

    .navbar-header {
        float: none;
        display: flex;
        justify-content: space-between;
        background: white;
        @extend .full-width;

        &:before, &:after {
            display: none;
        }

        .visible-nav {
            display: none !important;
            align-items: center;
            justify-content: space-between;

            @include bp-nav {
                display: flex !important;
            }

            &:before, &:after {
                display: none;
            }

            .form-search {
                max-width: 34rem;

                @include bp-lg {
                    max-width: 50rem;
                }

                [id^=edit-actions]  {
                    display: none;
                }
            }

            .menu {
                margin-left: 1.5rem;

                li {
                    a {
                        color: $brand-primary;
                        padding: 1rem;
                    }
                }
            }
        }
    }

    .logo {
        display: block;
        max-width: 150px;
        margin-top: .6rem;
        margin-bottom: .6rem;

        @include bp-lg {
            max-width: 200px;
        }
        

        .is-sticky & {
            max-width: 150px !important;
        }
    }

    .navbar-collapse {
        border: none;
        @include bp-nav {
            padding: 0;
        }
    }

    nav {
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }

        .hidden-nav {
            width: 100%;
            border-bottom: solid 3px $gray--300;
            padding-top: 1rem;
        }

        .menu {
            &.secondary {
                padding-top: 0 !important;
            }

            &:last-child { // Primary
                width: 100%;
                padding: 1rem 0;

                @include bp-nav {
                    display: flex;
                    flex-wrap: wrap;

                    &:before, &:after {
                        display: none;
                    }

                    > li > a {
                        margin-right: .5rem;
                        font-weight: 700;
                        letter-spacing: 1px;
                    }

                    .is-sticky & {
                        padding: .3rem 0;
                    }
                }


                

                > li > a {
                    color: white;
                    padding: .9rem 1.6rem;

                    &:hover, &:focus, &.active, &[aria-expanded=true], &.active-trail {
                        background: $brand-primary--600 !important;
                        color: white;
                    }

                    .is-sticky & {
                        padding: .6rem 1.6rem;
                    }
                }

                > li.megamenu.dropdown {
                    position: static;

                    .dropdown-menu {
                        max-width: none;
                        background: white;
                        position: relative;
                        left: 0;
                        right: 0;
                        z-index: 999 !important;

                        @include bp-nav {
                            position: absolute;
                            padding: 3rem;
                        }

                        &:before {
                            content: '';
                            background: white;
                            position: absolute;
                            left: 0;
                        }

                        //@media (min-width: 768px) {
                        //    left: calc(50vw - 360px);
                        //    right: calc(50vw - 360px);
                        //}
                        //
                        //@media (min-width: 992px) {
                        //    left: calc(50vw - 470px);
                        //    right: calc(50vw - 470px);
                        //}

                        @media (min-width: 1200px) {
                            left: calc(50vw - 578px);
                            right: calc(50vw - 578px);
                        }
                    }
                }
            }
        }
    }
}