html {
    height: 100%; // used to make footer always be at bottom (for short pages)
    font-size: 62.5%;
}

body {
    min-height: calc(100% + 1px);
    overflow-x: hidden;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    position: relative;


    @include bp-nav {
        //min-height: 1081px;
    }
}

.main-container {
    padding-bottom: 6rem;
}

.page--header {
    color: white;
    text-transform: none;
    margin-top: 0;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: static !important;
    background: $gray--600;

    h1 {
        margin: 0;
        text-transform: none;
        font-size: 2.8rem;

    }

    &--sticky-wrapper {
        height: auto !important;
    }

    .is-sticky & {


        @include bp-nav {
            position: fixed !important;
            top: 102px!important;
            z-index: 9 !important;
            left: 0;
            right: 0;
            margin: 0 !important;
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100% !important;
            font-size: 2rem;
        }


        h1 {
            @extend .container;
            margin: 0 auto;
        }
    }
}

.node-unpublished {
    background: transparent;
}

.page-checkout fieldset#edit-commerce-fieldgroup-pane-group-order-comments {
  float: none;
}
