.page-taxonomy-term {
    .content > .field-type-image {
        margin-bottom: 3rem;
    }

    .term-listing-heading {
        @extend .clearfix;
        margin-bottom: 2rem;
    }

    .landing-page-image img {
        margin: 0 auto;
    }
}