body.front {

    .view-home-page-slider {
        .slick-prev {
            left: 10px;
        }

        .slick-next {
            right: 10px;
        }

        .slick-slide {
            .slide {
                text-shadow: 1px 1px 3px rgba(black, .3);
                position: relative;

            }

            .caption {
                background: $gray--300;
                top: 0;
                bottom: 0;
                color: $text-color;
                margin: 0;
                overflow: hidden;
                padding-top: 3rem;
                padding-bottom: 3rem;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                padding: 1.5rem 3rem;

                &.text-white {
                    color: white;
                    background: $gray--500;
                }


                @include bp-md {
                    position: absolute;
                    width: 45%;
                    background: transparent !important;
                    padding: 0;
                }


                @media (min-width: 768px) {
                    //margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
                    padding-left: calc(50vw - 360px);
                }

                @media (min-width: 992px) {
                    //margin-left: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
                    padding-left: calc(50vw - 470px);
                }

                @media (min-width: 1200px) {
                    //margin-left: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
                    padding-left: calc(50vw - 570px);
                }


                &:before, &:after {
                    display: none;
                }

                h2, &-summary, &-link {
                    position: relative;
                    z-index: 9;
                }

                > h2 {
                    margin-top: 0;
                }

                .btn {
                    margin-top: 1.5rem;
                    text-shadow: none;
                }

                .caption-summary {
                    font-size: 1.8rem;
                }
            }

        }
    }


}


.cta-bg-block2 {
  .cta-bg-div {
    .item {
      padding: 25rem 6rem 4rem 6rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
