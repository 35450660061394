/*
|--------------------------------------------------------------------------
| Literature
|--------------------------------------------------------------------------
*/
.page-node-2458 .field-name-body > .field-items > .field-item {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &:before, &:after {
        display: none;
    }

}