/*
|--------------------------------------------------------------------------
|
| Product Category Thumbnails
| News & Events
| Featured Customers
| CTA Background Blocks
| Business Stats Block
|
|--------------------------------------------------------------------------
*/



/*
|--------------------------------------------------------------------------
| Product Category Thumbnails
|--------------------------------------------------------------------------
*/

.block-title {
    @extend .h3;
    margin-top: 0;
    margin-bottom: 3rem;
}

.product-category-thumbnails {
    @extend .full-width;

    .view-content {
        display: flex;
        flex-wrap: wrap;
        @extend .row;

        &:before, &:after {
            display: none;
        }

        .item {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @extend .col-md-3;
            margin-bottom: 3rem;

            &:before, &:after {
                display: none;
            }

            @include bp-sm {
                width: 50%;
            }

            
            @include bp-md {
                width: 25%;
            }

            .card {
                display: flex;
                flex-direction: column;
                min-height: 100%;
                align-items: flex-start;
                padding: 0;

                &:before, &:after {
                    display: none;
                }

                .image {
                    width: 100%;

                    img {
                        width: 100%;
                        max-width: none;
                    }
                }

                > h3 {
                    margin-top: 2rem;
                    padding: 0 1.5rem;
                }

                > a {
                    @extend .btn;
                    @extend .btn-arrow;
                    margin-top: auto;
                    margin-left: 1.5rem;
                    margin-bottom: 1.5rem;

                }
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| News & Events
|--------------------------------------------------------------------------
*/

#block-views-news-block {
    .slick-list {
        display: flex;

        &:before, &:after {
            display: none;
        }
    }

    .slick-track {
        display: flex;
        //width: 100% !important;
        //margin: 0 -15px;
        margin-left: 0 !important;

        &:before, &:after {
            display: none;
        }
    }

    .view-content {
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }
        
        .item {
            display: flex;

            &:before, &:after {
                display: none;
            }
            
            > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                &:before, &:after {
                    display: none;
                }
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| Featured Customers
|--------------------------------------------------------------------------
*/
.featured-customers {
    .slick-track {
        display: flex;
        align-items: center;

        &:before, &:after {
            display: none;
        }
    }
}


/*
|--------------------------------------------------------------------------
| CTA Background Blocks
|--------------------------------------------------------------------------
*/
.cta-bg-block {
    .row {
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }

        > div {
            background-repeat: no-repeat;
            background-size: cover;

            &:first-child .item {
                background-image: url(../images/bg-cta-block-1.jpg);
            }
            &:last-child .item {
                background-image: url(../images/bg-cta-block-2.jpg);
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-top: solid 4px $brand-primary;
            padding: 6rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            &:before, &:after {
                display: none;
            }

            h4 {
                margin-bottom: 3rem;
                margin-top: 0;
            }

            ul {
                margin-bottom: 2rem;
            }

            .btn {
                margin-top: auto;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| Business Stats Block
|--------------------------------------------------------------------------
*/

.business-stats-block {
    background: $brand-secondary;
    @extend .full-width;
    padding: 0;
    position: relative;
    z-index: 3;

    @include bp-lg {
        margin: 0;

        &:before {
            content: '';
            background: $brand-secondary;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: -200vw;
            right: -200vw;
            height: 100%;
            width: 9999%;
            display: block;
        }
    }


    .business-stats {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
        position: relative;
        z-index: 3;

        &:before, &:after {
            display: none;
        }

        h3 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0;
            width: 100%;

            @include bp-md {
                width: 50%;
            }

            @include bp-lg {
                width: 25%;

                &:first-child .h5:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -200vw;
                    right: 100%;
                    height: 100%;
                    display: block;
                    width: 200vw;
                    background: $brand-third;
                }

                &:last-child .h5:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 100%;
                    right: 200vw;
                    height: 100%;
                    display: block;
                    width: 200vw;
                    background: $brand-third;
                }
            }

            &:before, &:after {
                display: none;
            }

            > div {
                margin: 0;
                font-weight: 300;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &:before, &:after {
                    display: none;
                }
            }

            .h1 {
                padding: 3rem 0 3.5rem;
                font-size: 6rem;

            }

            .h5 {
                padding: 3rem 0;
                background: $brand-third;
                position: relative;
                margin-top: auto;
                &:before {
                    content: "";
                    display: block;
                    background: $brand-third;
                    width: 2rem;
                    height: 2rem;
                    position: absolute;
                    top: -1.5rem;
                    left: 50%;
                    transform: translateX(-50%) translateY(.5rem) rotate(-45deg);

                }
            }
        }
    }
}

